@import "./style/_variable.scss";

@font-face {
  font-family: "HarmonyOS_Sans_SC_Regular";
  src: url("#{$website-static}/fonts/HarmonyOS_Sans_SC_Regular.ttf");
  font-display: swap;
}

body {
  margin: 0;
  font-family: "HarmonyOS_Sans_SC_Regular", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $--text-gy1;
  background-color: $--main-color-3 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none !important;
  //color: $--main-color-2 !important;
}

a:hover {
  text-decoration: none !important;
}

/*清除元素默认的内外边距  */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/*让所有斜体 不倾斜*/
em,
i {
  font-style: normal;
}

/*去掉列表前面的小点*/
li {
  list-style: none;
}

/*图片没有边框   去掉图片底侧的空白缝隙*/
img {
  border: 0; /*ie6*/
  vertical-align: middle;
}

/*让button 按钮 变成小手*/
button {
  cursor: pointer;
  border: 0;
}

button,
input {
  /*取消轮廓线 蓝色的*/
  outline: none;
  border: 0;
}

.hide,
.none {
  display: none;
}

/*清除浮动*/
.clearfix:after {
  visibility: hidden;
  clear: both;
  display: block;
  content: ".";
  height: 0;
}

.clearfix {
  *zoom: 1;
}

.ellipsis-text {
  white-space: nowrap; /* 防止文本换行 */
  overflow: hidden; /* 隐藏超出容器宽度的部分 */
  text-overflow: ellipsis; /* 显示省略号 */
}

.btn-color {
  color: #225353;

  &:hover {
    color: #225353;
    transition: all 0.2s linear;
    background-color: color-mix(in srgb, #225353 24%, transparent);
  }

  &-selected {
    color: #ffffdf !important;
    background: #225353 !important;
  }
}

@media screen and (max-width: 1024px) {
  body {
    min-width: 1024px;
    overflow-x: scroll;
  }
}
